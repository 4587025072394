export default [
  {
    title: '分类名称',
    property: 'name',
    search: 'input',
    width: 120,
  },
  {
    title: '上级编码',
    property: 'parent_id',
    width: 120,
  },
  {
    title: '分类等级',
    property: 'level',
    width: 120,
  },
  {
    title: '关联1级分类编码',
    property: 'type1',
    search: 'input',
    width: 200,
  },
  {
    title: '关联2级分类编码',
    property: 'type2',
    search: 'input',
    width: 200,
  },
  {
    title: '关联3级分类编码',
    property: 'type3',
    search: 'input',
    width: 200,
  },
  {
    title: '关联4级分类编码',
    property: 'type4',
    search: 'input',
    width: 200,
  },
  {
    title: '关联5级分类编码',
    property: 'type5',
    search: 'input',
    width: 200,
  },
  {
    title: '关联1级分类名称',
    property: 'type_name1',
    search: 'input',
    width: 200,
  },
  {
    title: '关联2级分类名称',
    property: 'type_name2',
    search: 'input',
    width: 200,
  },
  {
    title: '关联3级分类名称',
    property: 'type_name3',
    search: 'input',
    width: 200,
  },
  {
    title: '关联4级分类名称',
    property: 'type_name4',
    search: 'input',
    width: 200,
  },
  {
    title: '关联5级分类名称',
    property: 'type_name5',
    search: 'input',
    width: 200,
  },
  {
    title: '状态',
    property: 'status',
    width: 120,
  },
  {
    title: '类型id',
    property: 'type_id',
    width: 120,
  },
];
