export default [
  {
    title: '名称',
    property: 'name',
    search: 'input',
    sort: false,
    width: 104,
  },
  {
    title: '类型',
    property: 'model_type1',
    search: 'select',
    sort: false,
    width: 96,
  },
  {
    title: '关联元数据',
    property: 'dict_type',
    search: 'select',
    sort: false,
    width: 138,
  },
  {
    title: 'API名称',
    property: 'api_name',
    search: 'input',
    sort: false,
    width: 189,
  },
  {
    title: '生效状态',
    property: 'status',
    search: 'select',
    sort: false,
    width: 124,
  },
  {
    title: '创建人',
    property: 'create_user',
    search: 'input',
    sort: false,
    width: 124,
  },
  {
    title: '创建时间',
    property: 'create_time',
    search: '',
    sort: true,
    width: 189,
  },
];
