export default [
  {
    title: '编码',
    property: 'code',
    width: 120,
  },
  {
    title: '科室名称',
    property: 'name',
    search: 'input',
    width: 120,
  },
  {
    title: '科室全名',
    property: 'full_name',
    search: 'input',
    width: 120,
  },
  {
    title: '上级编码',
    property: 'parent_id',
    width: 120,
  },
  {
    title: '标准科室层级',
    property: 'hierarchy_level',
    width: 120,
  },
  {
    title: '科室专长',
    property: 'specialty',
    width: 120,
  },
  {
    title: '等级顺序',
    property: 'rank_level',
    width: 120,
  },
  {
    title: '显示顺序',
    property: 'display_order',
    width: 120,
  },
  {
    title: '科室1级分类编码',
    property: 'department_id1',
    search: 'input',
    width: 200,
  },
  {
    title: '科室2级分类编码',
    property: 'department_id2',
    search: 'input',
    width: 200,
  },
  {
    title: '科室3级分类编码',
    property: 'department_id3',
    search: 'input',
    width: 200,
  },
  {
    title: '科室1级分类名称',
    property: 'department_name1',
    search: 'input',
    width: 200,
  },
  {
    title: '科室2级分类名称',
    property: 'department_name2',
    search: 'input',
    width: 200,
  },
  {
    title: '科室3级分类名称',
    property: 'department_name3',
    search: 'input',
    width: 200,
  },
  {
    title: '状态',
    property: 'status',
    width: 120,
  },
];
