export default [
  {
    title: '地区编码',
    property: 'code',
    width: 120,
  },
  {
    title: '地区名称',
    property: 'name',
    search: 'input',
    width: 120,
  },
  {
    title: '地区别名',
    property: 'alias_name',
    width: 120,
  },
  {
    title: '地区曾用名',
    property: 'former_name',
    width: 200,
  },
  {
    title: '上级编码',
    property: 'parent_id',
    width: 120,
  },
  {
    title: '地区等级',
    property: 'level',
    width: 120,
  },
  {
    title: '关联省编码',
    property: 'code1',
    search: 'input',
    width: 120,
  },
  {
    title: '关联市编码',
    property: 'code2',
    search: 'input',
    width: 120,
  },
  {
    title: '关联区编码',
    property: 'code3',
    search: 'input',
    width: 120,
  },
  {
    title: '关联乡编码',
    property: 'code4',
    search: 'input',
    width: 120,
  },
  {
    title: '关联村编码',
    property: 'code5',
    search: 'input',
    width: 120,
  },
  {
    title: '关联省名称',
    property: 'name1',
    search: 'input',
    width: 120,
  },
  {
    title: '关联市名称',
    property: 'name2',
    search: 'input',
    width: 120,
  },
  {
    title: '关联区名称',
    property: 'name3',
    search: 'input',
    width: 120,
  },
  {
    title: '关联乡名称',
    property: 'name4',
    search: 'input',
    width: 120,
  },
  {
    title: '关联村名称',
    property: 'name5',
    search: 'input',
    width: 120,
  },
  {
    title: '高德地图关联id',
    property: 'amap_id',
    width: 120,
  },
  {
    title: '高德地图关联名称',
    property: 'amap_name',
    width: 180,
  },
  {
    title: '地区中心点高德纬度',
    property: 'amap_center_lat',
    width: 180,
  },
  {
    title: '地区中心点高德经度',
    property: 'amap_center_lng',
    width: 180,
  },
  {
    title: '腾讯地图关联id',
    property: 'qmap_id',
    width: 120,
  },
  {
    title: '腾讯地图关联名称',
    property: 'qmap_name',
    width: 180,
  },
  {
    title: '地区中心点腾讯纬度',
    property: 'qmap_center_lat',
    width: 180,
  },
  {
    title: '地区中心点腾讯经度',
    property: 'qmap_center_lng',
    width: 180,
  },
  {
    title: '地区编码简写',
    property: 'stats_id',
    width: 120,
  },
  {
    title: '地区简称',
    property: 'stats_name',
    width: 120,
  },
  {
    title: '状态',
    property: 'status',
    width: 120,
  },
];
