<!--  -->
<template>
  <div>
    <div class="title">参考数据模型</div>
    <div class="container">
      <el-tabs v-model="activeName" :before-leave="handleClick" class="tabs">
        <el-tab-pane label="通用模型" name="通用模型" class="tabs-first"></el-tab-pane>
        <el-tab-pane label="特殊模型" name="特殊模型"></el-tab-pane>
      </el-tabs>
      <div class="tab-contents">
        <div>
          <el-button
            v-if="activeName === '通用模型'"
            type="primary"
            class="add-btn"
            @click="handleAdd"
          >
            新建模型
          </el-button>
        </div>
        <div v-if="filterList.length > 0" class="datalist-title-filter">
          <div v-for="(item, index) in filterList" :key="index" class="datalist-title-filter-item">
            {{ getFlitersTitle(item.name) }}: {{ item.value }}
            <i class="el-icon-close" @click="deleteFilterItem(item.name)"></i>
          </div>
          <el-button
            v-if="filterList.length"
            class="datalist-title-button"
            type="text"
            @click="deleteAllFilter"
          >
            重置
          </el-button>
        </div>
        <div :class="activeName === '通用模型' ? 'tabel-data' : ''" v-show="!loading">
          <eyao-table
            ref="table"
            class="table"
            :table-data="tableData"
            :total-num="totalNum"
            :select-page-size="pageSize"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            @filter-change="filterChange"
          >
            <el-table-column
              v-for="column in configList"
              :key="`config-${column.property}`"
              :label="column.title"
              :prop="column.property"
              :sortable="column.sort"
              :class-name="column.property === 'name' ? 'title-name' : ''"
              :min-width="column.width"
            >
              <template slot="header" slot-scope="scope">
                <!-- 表头 -->
                <eyao-table-search-header
                  v-if="column.search !== ''"
                  :form-type="column.search"
                  :property="column.property"
                  :title="column.title"
                  :scope="scope"
                  :options="valueOpts[column.property]"
                  @search="searchOpts"
                ></eyao-table-search-header>
                <!-- 无icon -->
                <template v-else>{{ column.title }}</template>
              </template>
              <template slot-scope="scope">
                <template v-if="column.property === 'name'">
                  <span class="name-link" @click="gotoDetail(scope.row)">
                    {{ scope.row[column.property] }}
                  </span>
                </template>
                <template v-else-if="column.property === 'model_type1'">
                  {{ scope.row[column.property] === 1 ? '预置' : '自定义' }}
                </template>
                <template v-else-if="column.property === 'dict_type'">
                  {{ scope.row[column.property] | formatDictType }}
                </template>
                <template v-else-if="column.property === 'status'">
                  <div class="status">
                    <div
                      :class="scope.row[column.property] === 1 ? 'status-effect' : 'status-invalid'"
                    ></div>
                    {{ scope.row[column.property] === 1 ? '生效' : '未生效' }}
                  </div>
                </template>
                <template v-else>{{ scope.row[column.property] }}</template>
              </template>
            </el-table-column>
            <!-- 操作列 -->
            <el-table-column label="操作" fixed="right" width="172" class-name="operate">
              <template slot-scope="scope">
                <el-button type="text" @click="gotoDetail(scope.row)" class="edit-value">
                  查看
                </el-button>
                <el-button type="text" @click="handleExport(scope.row)">导出详情</el-button>
              </template>
            </el-table-column>

            <template v-slot:empty>
              <div v-if="totalNum === 0 && !loading" class="no-data">
                <img
                  src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
                  alt="未查询到相关数据"
                />
                未查询到相关数据
              </div>
            </template>
          </eyao-table>
        </div>
        <!-- <div v-if="totalNum === 0 && !loading" class="no-data">
          <img
            src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
            alt="未查询到相关数据"
          />
          未查询到相关数据
        </div> -->
        <div class="listLoading" v-if="loading">
          <img src="@/assets/loading.gif" alt="loading" />
        </div>
      </div>
    </div>

    <!-- 新建模型弹窗 -->
    <el-drawer
      title="新建参考数据模型"
      :visible.sync="drawer"
      :before-close="handleClose"
      custom-class="drawer"
      size="480px"
    >
      <el-form
        ref="ruleForm"
        label-position="top"
        :model="formData"
        label-width="95px"
        class="form"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="formData.name" placeholder="请输入"></el-input>
          <span class="point">参考数据中文名称，显示用</span>
        </el-form-item>
        <el-form-item label="API名称" prop="apiName">
          <el-input v-model="formData.apiName" placeholder="请输入"></el-input>
          <!-- <span class="point">参考数据英文名称，显示用</span> -->
        </el-form-item>
        <el-form-item label="关联主数据" prop="relation">
          <el-radio-group v-model="formData.relation">
            <el-radio label="t_">通用</el-radio>
            <el-radio label="t_hco">HCO</el-radio>
            <el-radio label="t_hcp">HCP</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="层级类型" prop="hierarchy">
          <el-radio v-model="formData.hierarchy" :label="1">单层级</el-radio>
        </el-form-item>
        <el-form-item label="说明" prop="directions">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入"
            v-model="formData.directions"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btns">
        <el-button class="cancel-btn" @click="handleClose">取消</el-button>
        <el-button class="save-btn" type="primary" @click="handleSave">保存</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import eyaoTable from '@/components/table';
import eyaoTableSearchHeader from '@/components/table-search-header';
import configList from './config/model-list';
import { GetStandardValueModels, UpsertStandardValueModel, GetStandardValues } from '@/api/model';
import { GetObjURL } from '@/api/upload';
import { getBlob, downloadFile } from '@/utils/downloadExcel';
import universalConfig from '../reference-data-model-detail/config/universal';
import districtConfig from '../reference-data-model-detail/config/district';
import orgTypeConfig from '../reference-data-model-detail/config/orgType';
import departmentConfig from '../reference-data-model-detail/config/department';
import { jsonToXlsx } from '@/utils/xlsx.js';
export default {
  name: 'ReferenceDataModel',
  components: { eyaoTable, eyaoTableSearchHeader },
  filters: {
    formatDictType(val) {
      if (val === 't_') {
        return '通用';
      }
      if (val.indexOf('t_hco') > -1) {
        return 'HCO';
      }
      if (val.indexOf('t_hcp') > -1) {
        return 'HCP';
      }
      if (val === 't_product') {
        return '产品';
      }
      if (val === 't_product_sku') {
        return '产品(SKU)';
      }
      return '';
    },
  },
  data() {
    return {
      activeName: '通用模型',
      drawer: false,
      formData: {
        name: '',
        apiName: '',
        relation: 't_',
        hierarchy: 1,
        directions: '',
      },
      // relation: [
      //   { label: '通用', value: 't_' },
      //   { label: 'HCO', value: 't_hco' },
      //   { label: 'HCP', value: 't_hcp' },
      // ],
      // hierarchy: [
      //   { label: '单层级', value: 1 },
      //   { label: '多层级', value: 2 },
      // ],
      // rules: {
      //   name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      //   apiName: [{ required: true, message: '请输入API名称', trigger: 'blur' }],
      //   relation: [{ required: true, message: '请选择关联主数据', trigger: 'change' }],
      //   hierarchy: [{ required: true, message: '请选择层级类型', trigger: 'change' }],
      // },
      configList: [],
      tableData: [],
      totalNum: 0,
      pageSize: 10,
      pageIndex: 1,
      loading: false,
      valueOpts: {
        model_type1: [
          { label: '预置', value: 1 },
          { label: '自定义', value: 2 },
        ],
        dict_type: [
          { label: '通用', value: 't_' },
          { label: 'HCO', value: 't_hco' },
          { label: 'HCP', value: 't_hcp' },
          { label: '产品', value: 't_product' },
          { label: '产品(SKU)', value: 't_product_sku' },
        ],
        status: [
          { label: '生效', value: 1 },
          { label: '未生效', value: 2 },
        ],
      },
      filterList: [],
      filterObject: {},

      valueExportList: [],
    };
  },
  created() {
    this.configList = configList;
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      if (this.filterList.length) {
        const req = {
          page_size: this.pageSize,
          index: this.pageIndex,
          model_type1: 0,
          name: '',
          model_type2: this.activeName === '通用模型' ? 1 : 2,
          dict_type: '',
          api_name: '',
          status: 0,
          create_user: '',
        };
        this.filterList.forEach(item => {
          if (item.name === 'name') {
            req.name = item.value;
          }
          if (item.name === 'model_type1') {
            req.model_type1 = item.vals;
          }
          if (item.name === 'dict_type') {
            req.dict_type = item.vals;
          }
          if (item.name === 'api_name') {
            req.api_name = item.vals;
          }
          if (item.name === 'status') {
            req.status = item.vals;
          }
          if (item.name === 'create_user') {
            req.create_user = item.vals;
          }
        });
        GetStandardValueModels(req)
          .then(res => {
            console.log(res);
            this.tableData = res.datas;
            this.totalNum = res.total;
            this.loading = false;
          })
          .catch(err => {
            this.$message.error(err.message);
            this.loading = false;
          });
      } else {
        const req = {
          page_size: this.pageSize,
          index: this.pageIndex,
          model_type1: 0,
          name: '',
          model_type2: this.activeName === '通用模型' ? 1 : 2,
          dict_type: '',
          api_name: '',
          status: 0,
          create_user: '',
        };
        GetStandardValueModels(req)
          .then(res => {
            console.log(res);
            this.tableData = res.datas;
            this.totalNum = res.total;
            this.loading = false;
          })
          .catch(err => {
            this.$message.error(err.message);
            this.loading = false;
          });
      }
    },
    handleClick(activeName, oldActiveName) {
      console.log(activeName, oldActiveName);
      this.activeName = activeName;
      this.pageIndex = 1;
      this.pageSize = 10;
      this.getList();
    },
    handleAdd() {
      this.drawer = true;
    },
    handleClose() {
      this.drawer = false;
      this.formData = {
        name: '',
        apiName: '',
        relation: '',
        hierarchy: 1,
        directions: '',
      };
      this.$refs.ruleForm.resetFields();
    },
    handleSave() {
      if (this.formData.name === '' || this.formData.apiName === '') {
        this.$message.error('名称和API名称不可能为空');
      }
      const req = {
        operation: 1,
        name: this.formData.name,
        api_name: this.formData.apiName,
        dict_type: this.formData.relation,
        hierarchy_type: this.formData.hierarchy,
        comment: this.formData.directions,
        model_type2: 1,
        model_type1: 2,
      };
      UpsertStandardValueModel(req)
        .then(res => {
          console.log(res);
          // this.drawer = false;
          this.handleClose();
          this.$message.success('新建成功');
          this.g_tourl(`/dashboard/reference-data-model-detail?id=${res.id}`);
          // this.formData = {
          //   name: '',
          //   apiName: '',
          //   relation: '',
          //   hierarchy: 1,
          //   directions: '',
          // };
          // this.getList();
        })
        .catch(err => {
          this.$message.error(err.message);
        });
      // this.$refs.ruleForm.validate(valid => {
      //   if (valid) {
      //     const req = {
      //       operation: 1,
      //       name: this.formData.name,
      //       api_name: this.formData.apiName,
      //       dict_type: this.formData.relation,
      //       hierarchy_type: this.formData.hierarchy,
      //       comment: this.formData.directions,
      //       model_type2: 1,
      //       model_type1: 2,
      //     };
      //     UpsertStandardValueModel(req)
      //       .then(res => {
      //         console.log(res);
      //         // this.drawer = false;
      //         this.handleClose();
      //         this.$message.success('新建成功');
      //         // this.formData = {
      //         //   name: '',
      //         //   apiName: '',
      //         //   relation: '',
      //         //   hierarchy: 1,
      //         //   directions: '',
      //         // };
      //         this.getList();
      //       })
      //       .catch(err => {
      //         this.$message.error(err.message);
      //       });
      //   } else {
      //     return false;
      //   }
      // });
    },
    handleSizeChange(val) {
      console.log(val);
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(val);
      this.pageIndex = val;
      this.getList();
    },
    searchOpts(property, val, formType, label) {
      console.log(property, val, formType, label);
      if (formType === 'input') {
        this.filterChange({ [property]: { label: val, value: val, formType } });
      } else {
        this.filterChange({ [property]: { label, value: val, formType } });
      }
      this.pageIndex = 1;
      this.pageSize = 10;
      this.getList();
    },
    deleteFilterItem(val) {
      console.log(val, this.filterList);
      this.filterObject[val] = [];
      this.$refs.table.clearFilter([val]);
      this.updateFilterList();
      this.getList();
    },
    // 每次通过table组件原生调整筛选时更新筛选条件信息
    filterChange(filter) {
      console.log(filter);
      Object.keys(filter).forEach(key => {
        this.filterObject[key] = filter[key];
      });

      this.updateFilterList();
    },
    updateFilterList() {
      const list = [];
      Object.keys(this.filterObject).forEach(key => {
        if (this.filterObject[key].length !== 0) {
          list.push({
            name: key,
            value: this.filterObject[key].label,
            vals: this.filterObject[key].value,
          });
        }
      });
      this.filterList = list;
      console.log(this.filterList);
    },
    deleteAllFilter() {
      this.filterList = [];
      this.filterObject = {};
      this.pageIndex = 1;
      this.pageSize = 10;
      this.getList();
    },
    getFlitersTitle(property) {
      let title = '';
      this.configList.forEach(item => {
        if (property === item.property) title = item.title;
      });
      return title;
    },
    gotoDetail(val) {
      console.log(val);
      this.g_tourl(`/dashboard/reference-data-model-detail?id=${val.id}`);
    },
    handleExport(val) {
      console.log(val);
      let config = [];
      if (val.hierarchy_type === 2) {
        if (val.name === '行政区划') {
          config = districtConfig;
        }
        if (val.name === '标准科室') {
          config = departmentConfig;
        }
        if (val.name === '类型细分') {
          config = orgTypeConfig;
        }
      } else {
        config = universalConfig;
      }
      const req = {
        model_id: val.id,
      };
      GetStandardValues(req)
        .then(res => {
          console.log(res);
          if (res.url !== '') {
            GetObjURL({ url: res.url })
              .then(res => {
                console.log('url', res);
                const loading = this.$loading({
                  lock: true,
                  text: '正在导出请稍等',
                  spinner: 'el-exprot-icon',
                  background: 'rgba(255, 255, 255, 0.7)',
                });
                getBlob(res.data.url, function(blob) {
                  downloadFile(blob, `${val.name}参考数据详情`, () => {
                    loading.close();
                  });
                });
                // loading.close();
              })
              .catch(err => {
                this.$message.error(err.message);
              });
          } else {
            if (res.total === 0) {
              this.$message.error('当前模型暂无标准值');
              return false;
            }
            const data = res.datas.map(item => JSON.parse(item));
            const exportList = [];
            data.forEach(item => {
              const obj = {};
              config.forEach(({ title, property }) => {
                if (title === '状态') {
                  obj[title] = item[property] === '1' ? '生效' : '未生效';
                } else {
                  obj[title] = item[property];
                }
              });

              exportList.push(obj);
            });
            const loading = this.$loading({
              lock: true,
              text: '正在导出,请稍后...',
              spinner: 'el-exprot-icon',
              background: 'rgba(255, 255, 255, 0.7)',
            });
            jsonToXlsx([{ sheet: exportList }], `${val.name}参考数据详情`);
            loading.close();
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
