import request from '@/libs/request';

// 获取模型列表
export const GetStandardValueModels = data =>
  request('MdmAdmin/StandardValueModel/GetStandardValueModels', data);

// 新增模型
export const UpsertStandardValueModel = data =>
  request('MdmAdmin/StandardValueModel/UpsertStandardValueModel', data);
// 获取标准值列表
export const GetStandardValues = data =>
  request('MdmAdmin/StandardValueModel/GetStandardValues', data);
// 设置生效或失效
export const ActiveStandardValueModel = data =>
  request('MdmAdmin/StandardValueModel/ActiveStandardValueModel', data);
// 新增编辑删除标准值
export const UpsertStandardValue = data =>
  request('MdmAdmin/StandardValueModel/UpsertStandardValue', data);
// 获取历史记录
export const GetStandardModelOperationLogs = data =>
  request('MdmAdmin/StandardValueModel/GetStandardModelOperationLogs', data);
// 获取历史记录修改详情
export const GetStandardModelOperationLogDetails = data =>
  request('MdmAdmin/StandardValueModel/GetStandardModelOperationLogDetails', data);
