export default [
  {
    title: '标准值',
    property: 'label',
    search: 'input',
    width: 182,
  },
  {
    title: '值编码',
    property: 'code',
    search: 'input',
    width: 90,
  },
  {
    title: '英文名称',
    property: 'label_en',
    search: 'input',
    width: 189,
  },
  {
    title: '显示顺序',
    property: 'display_order',
    sort: true,
    width: 104,
  },
  {
    title: '等级顺序',
    property: 'rank_level',
    sort: true,
    width: 104,
  },
  {
    title: '更新人',
    property: 'update_user',
    search: 'input',
    width: 124,
  },
  {
    title: '更新时间',
    property: 'update_time',
    sort: true,
    width: 180,
  },
  {
    title: '备注',
    property: 'comment',
    width: 76,
  },
];
